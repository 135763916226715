import React, { useEffect } from 'react';
import { Button, Tooltip, Menu, Dropdown } from 'antd';
import { CheckOutlined, UpOutlined, VideoCameraAddOutlined, VideoCameraOutlined } from '@ant-design/icons';
import {BsCameraVideoOffFill,BsCameraVideoFill} from "react-icons/bs"
import classNames from 'classnames';
import './camera.scss';
import { MediaDevice } from '../video-types';
interface CameraButtonProps {
  isStartedVideo: boolean;
  isMirrored?: boolean;
  onCameraClick: () => void;
  onSwitchCamera: (deviceId: string) => void;
  onMirrorVideo?: () => void;
  onVideoStatistic?: () => void;
  className?: string;
  cameraList?: MediaDevice[];
  activeCamera?: string;
}
const CameraButton = (props: CameraButtonProps) => {
  const {
    isStartedVideo,
    className,
    cameraList,
    activeCamera,
    isMirrored,
    onCameraClick,
    onSwitchCamera,
    onMirrorVideo,
    onVideoStatistic
  } = props;
  const onMenuItemClick = (payload: { key: any }) => {
    if (payload.key === 'mirror') {
      onMirrorVideo?.();
    } else if (payload.key === 'statistic') {
      onVideoStatistic?.();
    } else {
      onSwitchCamera(payload.key);
    }
  };

  // useEffect(()=>{
  //   navigator.mediaDevices.getUserMedia({video: true})
  // },[])

  const menu = cameraList && cameraList.length > 0 && (
    <Menu onClick={onMenuItemClick} theme="dark" className="camera-menu">
      <Menu.ItemGroup title="Select a Camera">
        {cameraList.map((item) => (
          <Menu.Item key={item.deviceId} icon={item.deviceId === activeCamera && <CheckOutlined />}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.Item key="mirror" icon={isMirrored && <CheckOutlined />}>
        Mirror My Video
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="statistic">Video Statistic</Menu.Item>
    </Menu>
  );
  return (
    <div className={classNames('camera-footer', className)}>
      {isStartedVideo && menu ? (
        <Dropdown.Button
          className="camera-dropdown-button"
          size="large"
          overlay={menu}
          onClick={onCameraClick}
          trigger={['click']}
          type="ghost"
          icon={<UpOutlined />}
          placement="topRight"
        >
          <BsCameraVideoFill />
        </Dropdown.Button>
      ) : (
        <Tooltip title={`${isStartedVideo ? 'Stop Camera' : 'Start Camera'}`}>
          <Button
            className={classNames('camere-button', className)}
            style={{background:isStartedVideo ?"#2f80ec":'#ff7875' }}
            icon={isStartedVideo ?<BsCameraVideoFill /> :<BsCameraVideoOffFill />}
            ghost={true}
            shape="circle"
            size="large"
            onClick={onCameraClick}
          />
        </Tooltip>
      )}
    </div>
  );
};
export default CameraButton;
