import React from 'react';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { IconFont } from '../../../component/icon-font';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import {MdStopScreenShare,MdScreenShare} from "react-icons/md"
import './screen-share.scss';
interface ScreenShareButtonProps {
  isStartedScreenShare: boolean;
  onScreenShareClick: () => void;
}

interface ScreenShareLockButtonProps {
  isLockedScreenShare: boolean;
  onScreenShareLockClick: () => void;
}

const ScreenShareButton = (props: ScreenShareButtonProps) => {
  const { isStartedScreenShare, onScreenShareClick } = props;
  return (
    <Tooltip title={isStartedScreenShare ? 'Stop screen share' : 'Start screen share'}>
      <Button
        className={classNames('screen-share-button', {
          'started-share': isStartedScreenShare
        })}
        style={{background:isStartedScreenShare ?"#2f80ec":'#ff7875' }}
        icon={isStartedScreenShare ?  <MdScreenShare />:<MdStopScreenShare />}
        // eslint-disable-next-line react/jsx-boolean-value
        ghost={true}
        shape="circle"
        size="large"
        onClick={onScreenShareClick}
      />
    </Tooltip>
  );
};

const ScreenShareLockButton = (props: ScreenShareLockButtonProps) => {
  const { isLockedScreenShare, onScreenShareLockClick } = props;
  return (
    <Tooltip title={isLockedScreenShare ? 'Unlock screen share' : ' Lock screen share'}>
      <Button
        className="screen-share-button"
        style={{background:isLockedScreenShare ?"#2f80ec":'#ff7875' }}
        icon={isLockedScreenShare ?  <MdScreenShare />:<MdStopScreenShare />}
        // eslint-disable-next-line react/jsx-boolean-value
        ghost={true}
        shape="circle"
        size="large"
        onClick={onScreenShareLockClick}
      />
    </Tooltip>
  );
};

export { ScreenShareButton, ScreenShareLockButton };
