import React, { useState } from 'react';
import { Button, Modal } from 'antd';
interface PermissionModalProps {
    body:string
    isModalOpen:boolean;
    handleCancel:any
  }
const PermissionModal = (props:PermissionModalProps) => {
    const {body,isModalOpen,handleCancel} = props
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleOk = () => {
//     setIsModalOpen(false);
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

  return (
    <>
      <Modal title="Permission Not Granted!" open={isModalOpen} onOk={handleCancel} onCancel={handleCancel}>
        <p>{body}</p>
      </Modal>
    </>
  );
};

export default PermissionModal;